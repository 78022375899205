$fa-font-path: "../font";
$html-font-size: 16;
$color-white: #fff;
$color-black: #000;
$color-red: #ce1819;


@font-face {
  font-family: 'polyphonic condensed medium';
  src: url('../font/polyphonic_condensed_medium.woff2') format('woff2'),
      url('../font/polyphonic_condensed_medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


$primary-font: 'polyphonic condensed medium', Arial, sans-serif;