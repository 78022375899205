@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/magnific-popup/dist/magnific-popup";

html {
	background: $color-black;
	font-family: $primary-font;
	font-size: 16px;
	line-height: 1;
	width: 100%;
	overflow-x: hidden;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
	width: 100%;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;

	main {
		flex: 1;
	}
}

a {
	color: inherit;

	@include hover-focus {
		color: $color-white;
	}
}

a.btn,
button.btn {
	font-size: rems(24);
	padding: rems(12) rems(24);
	border: 1px solid $color-white;
	border-radius: rems(24);
	text-transform: uppercase;

	@include hover-focus {
		color: $color-black;
		background-color: $color-white;
	}

	&.btn-white {
		color: $color-black;
		background-color: $color-white;

		@include hover-focus {
			color: $color-white;
			background-color: $color-black;
		}
	}
}

ul.social {
	display: flex;
	align-items: center;
	list-style: none;
	gap: rems(24);
	padding: 0;
	margin: 0;

	li {
		position: relative;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			color: $color-black;
			transition: all 0.3s ease-in-out;
			width: rems(42);
			height: rems(42);

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background-color: $color-white;
				transition: background-color 0.3s ease-in-out;
			}

			i {
				position: relative;
				font-size: rems(24);
				z-index: 1;
			}

			&:hover:before {
				background-color: $color-red;
			}

			// &:hover {
			// 	color: $color-white; 
			// }
		}
	}
}

.container {
	max-width: 1000px;
}


header {
	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;

		h1 {
			z-index: 10;
			padding-bottom: rems(8);
			display: flex;
			flex-direction: column;
			align-items: center;

			img {
				width: 100%;

				&.logo {
					padding: 0 0 rems(10);
					border-bottom: 2px solid $color-red;
					margin: 0 0 rems(10);
				}

				&.logo-titles {
					padding: 0 rems(24) rems(20);
				}

				&.release-desktop {
					padding: 0 rems(24);

					@include phone-down {
						display: none;
					}
				}

				&.release-mobile {
					padding: 0 rems(24);

					@include tablet-up {
						display: none;
					}
				}
			}
		}

		.btn-group {
			z-index: 10;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			max-width: rems(420);

			.btn {
				width: 100%;
				text-align: center;
				margin-bottom: rems(24);
			}
		}
	}

	.social {
		z-index: 10;
	}

	.hero {
		margin-top: rems(-100);
		pointer-events: none;

		@include tablet-down {
			margin-top: rems(-60);
		}
	}

	.gap {
		width: 100%;
		height: rems(16);
		background-color: $color-white;
	}

}

main {
	background-color: $color-red;


	section#tour {
		padding: rems(44) 0;

		.container {
			h2 {
				color: $color-black;
				font-size: rems(72);
				text-align: center;
				text-transform: uppercase;
				margin: rems(30) 0;
			}

			.bit-widget-container {

				.bit-event {
					border: none !important;
				}

			}

		}
	}
}

footer {
	padding: rems(24) 0;
	text-transform: uppercase;
	font-size: rems(14);
	text-align: center;

	a {
		@include hover-focus {
			color: $color-red;
		}
	}
}


.popup {
	background-color: $color-black;
	padding: rems(50) rems(80);
	max-width: rems(400);
	color: $color-white;
	margin: 0 auto;
	position: relative;
	font-size: rems(22);
	text-align: center;
	background-color: $color-black;
	background-position: center;
	background-size: cover;
	border: 1px solid $color-white;

	.mfp-close {
		color: $color-white;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: rems(30);

		p {
			font-size: rems(22);
			font-weight: bold;
			text-align: center;
			font-family: $primary-font;

			span {}
		}

		.inputs-wrap {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}

		label {
			display: block;
		}

		input {
			border: none;
			margin-bottom: rems(35);
		}

		select {
			width: 100%;
			margin-bottom: rems(35);
		}

		option {
			width: 100%;
		}
	}
}